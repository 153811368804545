import React from 'react'
import {Row, Col} from 'react-bootstrap'

import Layout from '../layouts/layout'

import Formularz from '../components/Formularz'

import banerjpg from '../images/headerPhotos/kontakt.jpg'

const contact = ({data}) => {
  return(
    <Layout
      headerPhoto={<img src={banerjpg} alt="kontakt"/>}
    >
      <h2>KONTAKT</h2>
      <Row className="p-5 w-100 d-flex justify-content-center">
        <Col lg={4}>
          <h3>Dane kontaktowe</h3>
          <p>
            Przedsiębiorstwo Usługowe "Jastrych" s.c.<br/>
            Jacek i Wojciech Marszałkowscy<br/><br/>
            80-298 Gdańsk, ul. Fundamentowa 2b/14<br/>
            NIP 9570873250, Regon 193001923<br/><br/>
            504 044 591 - Wojtek Marszałkowski<br/>
            509 655 424 - Jacek Marszałkowski<br/>
            e-mail: biuro@jastrych.com.pl<br/>
          </p>
        </Col>      
        <Col lg={4}>
          <h3>Napisz do nas</h3>
          <Formularz imie nazwisko mail wiadomosc />     
        </Col>
      </Row>
    </Layout>
  )
}

export default contact